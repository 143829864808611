import {reactive, toRefs, computed, defineComponent, onBeforeMount, onMounted, getCurrentInstance} from 'vue';
import ChildOrderListUtil ,{IChildOrderListDataObj} from './childOrderListUtil';

export default defineComponent ({
    name: 'ChildOrderList',
    props: {
        //以弹出框的形式包含该模块的时候会传入该参数(由jh.core.js的dialog传入)，在此模块中用该参数控制固定列的显示
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IChildOrderListDataObj=reactive<IChildOrderListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                gridTitle:'小单明细',
                isShowFixCol:false,
                multiple: true,//是否允许多选
                border: false,
                queryParam: {
                    from:props.engineParams?props.engineParams.from:'',
                    customerId:props.engineParams?props.engineParams.customerId:'',
                    cardFrom:'ChildOrderList',
                },
                modelMethod: utils.OrderProviderApi.buildUrl('/childOrder/pageData')
            },
            otherParams:{

            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ChildOrderListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //---------------------------computed---------------------------

        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'psbFlag'==params.comboId){
                    return [
                            {value:0,label:'未上传'},{value:1,label:'待提交'}
                        ];
                }
            }
        })

        return{
            ...toRefs(dataObj),comboSelect
        }
    }
});