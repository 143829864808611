import BaseBean from "@/utils/BaseBean";

export interface IChildOrderListDataObj {
    utilInst:ChildOrderListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class ChildOrderListUtil extends BaseBean{
    public dataObj:IChildOrderListDataObj

    constructor(proxy:any,dataObj:IChildOrderListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}