import BaseBean from "@/utils/BaseBean";
import _ from "lodash";

export interface IQpCardDataObj {
    utilInst:QpCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class QpCardUtil extends BaseBean{
    public dataObj:IQpCardDataObj;

    constructor(proxy:any,dataObj:IQpCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        this.dataObj.otherParams.customerData=await this.utils.OrderProviderApi.getAllCustomer({params:{from:'qpCard'}});//查询所有客户
    }
    //检查所选行是否已经存在
    public checkExist(row:any):boolean{
        let tbData=this.dataObj.refMap.get('qpDetail').getDetailData();
        return tbData.findIndex((item:any)=>item.F_CHILD_ORDER_DETAIL_ID==row.F_ID)==-1;
    }
    //处理弹出框确认
    public doSureChoose(selectRows:any):any{
        selectRows.forEach((row:any)=>{
            if(this.checkExist(row)){//不存在
                row.F_CHILD_ORDER_DETAIL_ID=row.F_ID;
                delete row.F_ID;//删除F_ID，否则addRow的时候辉往更新数组里面添加
                /**
                 * 这里出了一个问题，找了好久才找到这里，问题如下:
                 * 从弹出框选择第n跳记录添加到当前明细表格中，顺序号加入是第1行，问题来了，在当前明细表中，rowIndex本来该为0，结果rowIndex却是n，
                 * 在editTableUtil的77行点击行获取rowindex的时候就会报错，所以就必须把rowIndex删除，删除了之后，rowindex会在
                 * :row-class-name那里添加上，可是遇到一个很怪的问题，我即便这样做了，但是rowindex还是以前的，所以我才用下发深复制一份，这样rowindex就改变了
                 * */
                delete row.rowIndex;
                let tempRow = _.cloneDeep(row);
                tempRow.F_MAX_NUM=row.F_NUM-row.F_FY_NUM;//本次可以入库的最大数量,用作控件限制输入用,可惜只能新增的时候用，编辑的时候就不能限制了
                tempRow.F_SJRKSL=row.F_NUM-row.F_FY_NUM;//默认填入本次可以入库的最大数量
                this.dataObj.refMap.get('qpDetail').addRow(tempRow);
            }
        })
    }

    //提交
    public async submit():Promise<void>{
        this.utils.Tools.configBox({
            message:'确定提交吗?',
            sureFn:async ()=> {
                this.dataObj.form.saveType='submit';
                await this.proxy.engine.saveHandler();
            }
        });
    }
    public async doQrfhtz():Promise<void>{
        this.utils.Tools.configBox({
            message:'确认发货通知?',
            sureFn:async ()=> {
                let res=await this.utils.OrderProviderApi.qrfhtz({params:{id:this.dataObj.form.id}});
                if(res.result){
                    this.utils.Tools.success({message:'操作成功'});
                    await this.proxy.engine.engineUtil.doAddOrLoad(this.dataObj.form.id,'card');
                    this.proxy.engine.engineParams.ownerComp.queryHandler(false);
                }
            }
        });
    }
}