import QpCard from './QpCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance,nextTick} from 'vue';
import QpListUtil ,{IQpListDataObj} from './qpListUtil';
export default defineComponent ({
    name: 'QpList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IQpListDataObj=reactive<IQpListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {},
                modelComp: QpCard,
                cardFrom:'QpList',
                modelMethod: utils.OrderProviderApi.buildUrl('/qp/pageData')
            },
            otherParams:{

            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new QpListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'flag'==params.comboId){
                    return [{value:0,label:'未提交'},{value:1,label:'已提交'},{value:2,label:'已通知发货'}]
                }
            }
        })

        const formatPageInfo=(options:any)=>{
            return options;
        }

        return{
            ...toRefs(dataObj),comboSelect,formatPageInfo
        }
    }
});